import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DeleteConfirmation } from "./delete-confirmation";

@Component({
	selector: "app-dialog-delete",
	templateUrl: "./dialog-delete.component.html",
	styleUrls: ["./dialog-delete.component.css"]
})
export class DialogDeleteComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<DialogDeleteComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DeleteConfirmation
	) {}

	ngOnInit() {}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onConfirm(): void {
		this.data.confirmado = true;
		this.dialogRef.close();
	}
}
