import { Injectable, OnInit } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { Subject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LayoutNotificacoesRealtimeAdminService {

	private subject = new Subject<any>();  
	private hubConnection: signalR.HubConnection;

	constructor() { }

	public startConnection = () => { 
		this.hubConnection = new signalR.HubConnectionBuilder()
			.withUrl('/hubs/tickethub', { accessTokenFactory: () => localStorage.getItem('token') })
			.build();

		this.hubConnection
			.start()
			.then(() => {
                this.hubConnection.invoke("SubscribeAdministradorNosDepartamentos"); 
			})
			.catch(err => console.log('Error while starting connection: ' + err))
	}

    public novoTicketCriado = () => {
        this.hubConnection.on('novoTicketCriado', (data) => { 
			this.subject.next(data);
		});
    }  

    getNovoTicketCriado(): Observable<any> {
		return this.subject.asObservable();
	}  

}