import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface LayoutConfig {
    title?: string;
}

@Injectable()
export class LayoutService {

    private title: string;
    private layoutConfig: LayoutConfig = {};
    private pagina: number;
    private notificacoes: any;
    public sujectAtualizarTicketsRestens: Subject<any> = new Subject<any>();

    constructor(private http: HttpClient) {

    }

    public setPagina(pagina: number) {
        this.pagina = pagina;
    }

    public getPagina() {
        return this.pagina;
    }

    public getNotificacoes() {
        return this.notificacoes;
    }


    public setTitle(title: string) {
        this.layoutConfig.title = title;
    }

    public getTitle() {
        return this.layoutConfig.title;
    }

    public getInformacoes() {
        var informacoes = JSON.parse(localStorage.getItem("informacoes-usuario-logado"));
        return informacoes;
    }

    public logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("informacoes-usuario-logado");
    }

    public getNotifications() {
        return new Promise((resolve, reject) => {
            this.http.get('api/cliente/notificacoes?pagina=' + this.pagina)
                .pipe(take(1))
                .subscribe((response: any) => {
                    this.notificacoes = response;
                    resolve(response);
                }, reject);
        });
    }

    public visualizarNotificacao(notificacaoId: number) {
        return new Promise((resolve, reject) => {
            this.http.put('api/cliente/notificacoes/visualizar/' + notificacaoId, null)
                .pipe(take(1))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    public ticketsRestantes() {
        return this.http.get('api/cliente/tickets/TicketsRestantes/');
    }

    public ticketsPendentesAvaliacao() {
        return this.http.get('api/cliente/TicketAvaliacao/PendentesAvaliacao/').pipe(take(1));
    }

    public forcarAtualizacaoTicketsRestantes() {
        this.sujectAtualizarTicketsRestens.next(true);
    }
}
