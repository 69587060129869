import { Injectable, EventEmitter } from "@angular/core";
import { Contrato } from "./contrato";
import { HttpClient } from "@angular/common/http";
import { PagedList } from "src/app/core/model/paged-list";
import { take } from "rxjs/internal/operators";
import { Subject } from "rxjs";
import { ContratoFilter } from './contrato-list/contrato-filter/contrato-filter';

@Injectable({
	providedIn: "root"
})
export class ContratoService {
	contratos: Contrato[];
	private pageList: PagedList;
	private pagina: number = 1;
	private filter: ContratoFilter = new ContratoFilter();
	public eventReload$ = new Subject();

	constructor(private http: HttpClient) {}

	getList() {
		return this.contratos;
	}

	setList(list: Contrato[], pagedList: PagedList) {
		this.contratos = list;
		this.pageList = pagedList;
	}

	setFiltersAndUpdatePage(fl: ContratoFilter) {
		this.filter = fl;
		this.getLista()
			.pipe(take(1))
			.subscribe(response => this.eventReload$.next(response));
	}

	getPaginaNumber() {
		return this.pagina;
	}

	setPaginaNumber(page: number) {
		this.pagina = page;
	}

	getTotalItemCount() {
		return this.pageList.totalItemCount;
	}

	action(value: Contrato) {
		if (value.contratoId != undefined && value.contratoId > 0)
			return this.editar(value);
		else return this.adicionar(value);
	}

	getItem(id: number) {
		return this.http.get("api/administrador/contrato/" + id);
	}

	getLista() {
		return this.http.post(
			"api/administrador/contrato?pagina=" + this.pagina,
			{
				palavraChave: this.filter.keyword
			}
		);
	}

	editar(value: Contrato) {
		return this.http.put(
			"api/administrador/contrato/editar/" + value.contratoId,
			value
		);
	}

	adicionar(value: Contrato) {
		value.statusId = 1;
		return this.http.post("api/administrador/contrato/incluir", value);
	}

	deletar(id: number) {
		return this.http.delete("api/administrador/contrato/deletar/" + id, {});
	}
}
