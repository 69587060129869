import { Injectable, EventEmitter } from "@angular/core";
import { CanalTecnico } from "./canal-tecnico";
import { HttpClient } from "@angular/common/http";
import { PagedList } from "src/app/core/model/paged-list";
import { take } from "rxjs/internal/operators";
import { Subject } from "rxjs";
import { CanalTecnicoFilter } from './canal-tecnico-list/canal-tecnico-filter/canal-tecnico-filter';

@Injectable({
	providedIn: "root"
})
export class CanalTecnicoService {
	canalTecnicos: CanalTecnico[];
	private pageList: PagedList;
	private pagina: number = 1;
	private filter: CanalTecnicoFilter = new CanalTecnicoFilter();
	public eventReload$ = new Subject();

	constructor(private http: HttpClient) {
		this.filter.keyword = "";
	}

	getList() {
		return this.canalTecnicos;
	}

	setList(list: CanalTecnico[], pagedList: PagedList) {
		this.canalTecnicos = list;
		this.pageList = pagedList;
	}

	setFiltersAndUpdatePage(fl: CanalTecnicoFilter) {
		this.filter = fl;
		this.getLista()
			.pipe(take(1))
			.subscribe(response => this.eventReload$.next(response));
	}

	getPaginaNumber() {
		return this.pagina;
	}

	setPaginaNumber(page: number) {
		this.pagina = page;
	}

	getTotalItemCount() {
		return this.pageList.totalItemCount;
	}

	action(value: any, formData: FormData) {
		if (value.canalTecnicoId != undefined && value.canalTecnicoId > 0)
			return this.editar(value.canalTecnicoId, formData);
		else return this.adicionar(formData);
	}

	getItem(id: number) {
		return this.http.get("api/administrador/canalTecnico/" + id);
	}

	getLista() {
		return this.http.get(
			"api/administrador/canalTecnico?pagina=" + this.pagina + "&palavraChave=" + this.filter.keyword)
	}

	getStatusCanalTecnico() {
		return this.http.get("api/administrador/status?escopo=CanalTecnico");
    }

    getCanalTecnicoCategoria() {
        return this.http.get("api/administrador/canalTecnico/Categorias");
    }

	editar(canalTecnicoId: string, value: FormData) {
		return this.http.put("api/administrador/canalTecnico/editar/" + canalTecnicoId, value);
	}

	adicionar(value: FormData) {
		return this.http.post("api/administrador/canalTecnico/incluir", value);
	}

	deletar(id: number) {
		return this.http.delete("api/administrador/canalTecnico/deletar/" + id, {});
	}
 
}
