import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { HttpClient } from '@angular/common/http';

export interface LayoutAdminConfig {
  title?: string;
}

@Injectable()
export class LayoutAdminService {

  private layoutConfig: LayoutAdminConfig = {};

  constructor() { }

  public setTitle(title: string) {
    this.layoutConfig.title = title;
  }

  public getTitle() {
    return this.layoutConfig.title;
  }

  public getInformacoes() {
    var informacoes = JSON.parse(localStorage.getItem("informacoes-usuario-logado"));  
    return informacoes;
  }

  public logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("informacoes-usuario-logado");
  }


}
