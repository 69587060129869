import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class LoginAdminService {
  constructor(private http: HttpClient, private jwtHelper: JwtHelperService) { }

  verificarCliente(usuario) {
    return new Promise((resolve, reject) => {
		this.http.post('api/administrador/login/ValidarUsuario', { usuario: usuario })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

	validarSenha(senha, administradorId) {
    return new Promise((resolve, reject) => {
      this.http.post('api/administrador/login/validarSenha', { senha: senha, administradorId: administradorId })
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    // Check whether the token is expired and return
    // true or false 
    return !this.jwtHelper.isTokenExpired(token);
  }

  public logout() {
    localStorage.removeItem('token');
  }

  public tokenDecoded(): any {
    const token = localStorage.getItem('token');

    if (!this.jwtHelper.isTokenExpired(token))
      return this.jwtHelper.decodeToken(token)
    else
      return null;
  }

  public getInformacoes() {
    var informacoes = JSON.parse(localStorage.getItem("informacoes-usuario-logado"));  
    return informacoes;
  }

}
