import { Injectable, EventEmitter } from "@angular/core";
import { Cliente } from "./cliente";
import { HttpClient } from "@angular/common/http";
import { PagedList } from "src/app/core/model/paged-list";
import { take } from "rxjs/internal/operators";
import { Subject } from "rxjs";
import { ClienteFilter } from "./cliente-list/cliente-filter/cliente-filter";
import { Status } from "src/app/core/models/status";

@Injectable({
  providedIn: "root",
})
export class ClienteService {
  clientes: Cliente[];
  private pageList: PagedList;
  private pagina: number = 1;
  private filter: ClienteFilter = new ClienteFilter();
  public eventReload$ = new Subject();

  constructor(private http: HttpClient) {}

  getList() {
    return this.clientes;
  }

  getStatusPossiveis() {
    return this.http.get<Status[]>(
      "/api/administrador/clientes/status-possiveis"
    );
  }

  setList(list: Cliente[], pagedList: PagedList) {
    this.clientes = list;
    this.pageList = pagedList;
  }

  setFiltersAndUpdatePage(fl: ClienteFilter) {
    this.setPaginaNumber(1);
    this.filter = fl;
    this.getLista()
      .pipe(take(1))
      .subscribe((response) => this.eventReload$.next(response));
  }

  getPaginaNumber() {
    return this.pagina;
  }

  setPaginaNumber(page: number) {
    this.pagina = page;
  }

  getTotalItemCount() {
    return this.pageList.totalItemCount;
  }

  action(value: Cliente) {
    if (value.clienteId != undefined && value.clienteId > 0)
      return this.editar(value);
    else return this.adicionar(value);
  }

  getItem(id: number): any {
    return this.http.get("api/administrador/clientes/" + id);
  }

  getLista() {
    return this.http.post("api/administrador/clientes?pagina=" + this.pagina, {
      palavraChave: this.filter.nome,
      email: this.filter.email,
      cnpj: this.filter.cnpj,
    });
  }

  editar(value: Cliente) {
    return this.http.put(
      "api/administrador/clientes/editar/" + value.clienteId,
      value
    );
  }

  adicionar(value: Cliente) {
    value.statusId = 1;
    let cli: Cliente = value;
    return this.http.post("api/administrador/clientes/incluir", cli);
  }

  deletar(id: number) {
    return this.http.delete("api/administrador/clientes/deletar/" + id, {});
  }
}
