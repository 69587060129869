import { Injectable } from "@angular/core";
import { PagedList } from "../../../core/model/paged-list";
import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ClienteContrato } from "./cliente-contrato";
import { Contrato } from "../../contratos/contrato";

@Injectable({
	providedIn: "root"
})
export class ClienteContratoService {
	contratos: ClienteContrato[];
	private pageList: PagedList;
	private pagina: number = 1;
	public eventReload$ = new Subject();

	constructor(private http: HttpClient) {}

	getList() {
		return this.contratos;
	}

	setList(list: ClienteContrato[], pagedList: PagedList) {
		this.contratos = list;
		this.pageList = pagedList;
	}

	getPaginaNumber() {
		return this.pagina;
	}

	setPaginaNumber(page: number) {
		this.pagina = page;
	}

	getTotalItemCount() {
		return this.pageList.totalItemCount;
	}

	action(value: ClienteContrato) {
		if (value.clienteContratoId != undefined && value.clienteContratoId > 0)
			return this.editar(value);
		else return this.adicionar(value);
	}

	getItem(id: number) {
		return this.http.get("api/administrador/clientecontrato/" + id);
	}

	getLista(clienteId: number) {
		return this.http.post(
			"api/administrador/clientecontrato?pagina=" +
				this.pagina +
				"&clienteId=" +
				clienteId,
			{}
		);
	}

	editar(value: ClienteContrato) {
		return this.http.put(
			"api/administrador/clientecontrato/editar/" + value.clienteContratoId,
			value
		);
	}

	adicionar(value: ClienteContrato) {
		return this.http.post(
			"api/administrador/clientecontrato/incluir",
			value
		);
	}

	deletar(id: number) {
		return this.http.delete(
			"api/administrador/clientecontrato/deletar/" + id,
			{}
		);
	}
}
